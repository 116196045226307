import {INavegadorCheck, INavegadorRegistro, ITag} from "services-comun-ed/modules/services/navegadores/interface";
import {NavegadoresWebFrontendRequest} from "services-comun-ed/modules/services/navegadores/frontend-web";
import cookies from "../utils/cookies";
import dominio from "services-comun-ed/modules/portal/ed";

declare global {
    interface Window {
        navegador: any;
    }
}

class Navegador {
    /* STATIC */
    public static async init(gravitecId?: string): Promise<Navegador> {
        const siteUrl = new URL(window.location.href);
        const levels = siteUrl.pathname.split('/').slice(1, 3).filter(Boolean);

        let tag: ITag = {};
        if (levels && levels.length > 0 && window.tipologia === 'noticia') {
            const key = (levels[0] === "futbol") ? levels[1] : levels[0];
            tag[key] = [new Date().getTime()];
        }

        const data: INavegadorRegistro = {
            hash: cookies.get("ed-check"),
            tags: [],
            offset: new Date().getTimezoneOffset(),
        };

        if (tag) {
            data.tags?.push(tag);
        }

        if (gravitecId && gravitecId.length > 0) data.gravitec = {
            id: gravitecId,
        };

        const hash = await NavegadoresWebFrontendRequest.check(data);

        const salida = new this({
            hash,
            offset: data.offset,
        });
        salida.save();

        return salida;
    }

    /* INSTANCE */
    public get hash(): string { return this.data.hash; }

    protected constructor(private readonly data: INavegadorCheck) {

    }

    protected save(): void {
        const fecha = new Date();
        fecha.setUTCFullYear(fecha.getUTCFullYear()+1);

        cookies.set("ed-check", this.data.hash, fecha, dominio.cookies);
    }
}

window.navegador = Navegador;
